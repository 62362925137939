body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  background-color: #35383b;
  max-width: 600px;
  margin: 20px auto;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}

.header {
  font: 20px Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
}

.recommendation-list li {
  margin-bottom: 10px;
}

.recommendation-list button {
  color: #eee;
  background-color: #060606;
  padding: 5px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: bold;
  border: none;
  border-radius: 4px;
}

body {
  background-color: #060606;
  color: #eee;
  font-size: 16px;
  width: 100vw;
  margin: 0 auto;
}

.button-link {
  display: inline-block;
  padding: 10px 20px;
  color: #eee;
  background-color: #060606;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.button-link:hover {
  background-color: #5c5e61;
}

@media screen and (min-width: 300px) {
  .button-link {
    width: auto;
  }
}
